exports.components = {
  "component---src-blog-post-tsx": () => import("./../../../src/blog-post.tsx" /* webpackChunkName: "component---src-blog-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blogs-ai-tsx": () => import("./../../../src/pages/blogs/ai.tsx" /* webpackChunkName: "component---src-pages-blogs-ai-tsx" */),
  "component---src-pages-blogs-ecshop-tsx": () => import("./../../../src/pages/blogs/ecshop.tsx" /* webpackChunkName: "component---src-pages-blogs-ecshop-tsx" */),
  "component---src-pages-blogs-tech-tsx": () => import("./../../../src/pages/blogs/tech.tsx" /* webpackChunkName: "component---src-pages-blogs-tech-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-blogs-web-business-development-tsx": () => import("./../../../src/pages/blogs/web-business-development.tsx" /* webpackChunkName: "component---src-pages-blogs-web-business-development-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-casestudy-index-tsx": () => import("./../../../src/pages/casestudy/index.tsx" /* webpackChunkName: "component---src-pages-casestudy-index-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-tsx": () => import("./../../../src/pages/cookie.tsx" /* webpackChunkName: "component---src-pages-cookie-tsx" */),
  "component---src-pages-demos-tsx": () => import("./../../../src/pages/demos.tsx" /* webpackChunkName: "component---src-pages-demos-tsx" */),
  "component---src-pages-dropshipping-tsx": () => import("./../../../src/pages/dropshipping.tsx" /* webpackChunkName: "component---src-pages-dropshipping-tsx" */),
  "component---src-pages-ecommerce-jsx": () => import("./../../../src/pages/ecommerce.jsx" /* webpackChunkName: "component---src-pages-ecommerce-jsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-home-2-tsx": () => import("./../../../src/pages/home2.tsx" /* webpackChunkName: "component---src-pages-home-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-online-presence-tax-tsx": () => import("./../../../src/pages/online-presence-tax.tsx" /* webpackChunkName: "component---src-pages-online-presence-tax-tsx" */),
  "component---src-pages-online-presence-tsx": () => import("./../../../src/pages/online-presence.tsx" /* webpackChunkName: "component---src-pages-online-presence-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-samplesites-clinic-tsx": () => import("./../../../src/pages/samplesites/clinic.tsx" /* webpackChunkName: "component---src-pages-samplesites-clinic-tsx" */),
  "component---src-pages-samplesites-petgoodsec-tsx": () => import("./../../../src/pages/samplesites/petgoodsec.tsx" /* webpackChunkName: "component---src-pages-samplesites-petgoodsec-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-services-ai-apps-tsx": () => import("./../../../src/pages/services/ai/apps.tsx" /* webpackChunkName: "component---src-pages-services-ai-apps-tsx" */),
  "component---src-pages-services-ai-chatbot-fukuoka-tsx": () => import("./../../../src/pages/services/ai/chatbot-fukuoka.tsx" /* webpackChunkName: "component---src-pages-services-ai-chatbot-fukuoka-tsx" */),
  "component---src-pages-services-ai-chatbot-tsx": () => import("./../../../src/pages/services/ai/chatbot.tsx" /* webpackChunkName: "component---src-pages-services-ai-chatbot-tsx" */),
  "component---src-pages-services-ai-content-tsx": () => import("./../../../src/pages/services/ai/content.tsx" /* webpackChunkName: "component---src-pages-services-ai-content-tsx" */),
  "component---src-pages-services-ai-deepseek-chatbot-tsx": () => import("./../../../src/pages/services/ai/deepseek-chatbot.tsx" /* webpackChunkName: "component---src-pages-services-ai-deepseek-chatbot-tsx" */),
  "component---src-pages-services-ai-ivr-tsx": () => import("./../../../src/pages/services/ai/ivr.tsx" /* webpackChunkName: "component---src-pages-services-ai-ivr-tsx" */),
  "component---src-pages-services-ai-moderation-tsx": () => import("./../../../src/pages/services/ai/moderation.tsx" /* webpackChunkName: "component---src-pages-services-ai-moderation-tsx" */),
  "component---src-pages-services-ai-summarization-tsx": () => import("./../../../src/pages/services/ai/summarization.tsx" /* webpackChunkName: "component---src-pages-services-ai-summarization-tsx" */),
  "component---src-pages-services-business-accountant-tsx": () => import("./../../../src/pages/services/business/accountant.tsx" /* webpackChunkName: "component---src-pages-services-business-accountant-tsx" */),
  "component---src-pages-services-business-appdev-tsx": () => import("./../../../src/pages/services/business/appdev.tsx" /* webpackChunkName: "component---src-pages-services-business-appdev-tsx" */),
  "component---src-pages-services-business-clinic-tsx": () => import("./../../../src/pages/services/business/clinic.tsx" /* webpackChunkName: "component---src-pages-services-business-clinic-tsx" */),
  "component---src-pages-services-business-consultation-tsx": () => import("./../../../src/pages/services/business/consultation.tsx" /* webpackChunkName: "component---src-pages-services-business-consultation-tsx" */),
  "component---src-pages-services-business-ec-guide-tsx": () => import("./../../../src/pages/services/business/ec-guide.tsx" /* webpackChunkName: "component---src-pages-services-business-ec-guide-tsx" */),
  "component---src-pages-services-business-ecommerce-tsx": () => import("./../../../src/pages/services/business/ecommerce.tsx" /* webpackChunkName: "component---src-pages-services-business-ecommerce-tsx" */),
  "component---src-pages-services-business-hokusetsu-webrenewal-tsx": () => import("./../../../src/pages/services/business/hokusetsu/webrenewal.tsx" /* webpackChunkName: "component---src-pages-services-business-hokusetsu-webrenewal-tsx" */),
  "component---src-pages-services-business-music-tsx": () => import("./../../../src/pages/services/business/music.tsx" /* webpackChunkName: "component---src-pages-services-business-music-tsx" */),
  "component---src-pages-services-business-restaurants-tsx": () => import("./../../../src/pages/services/business/restaurants.tsx" /* webpackChunkName: "component---src-pages-services-business-restaurants-tsx" */),
  "component---src-pages-services-business-systems-tsx": () => import("./../../../src/pages/services/business/systems.tsx" /* webpackChunkName: "component---src-pages-services-business-systems-tsx" */),
  "component---src-pages-services-business-webrenewal-tsx": () => import("./../../../src/pages/services/business/webrenewal.tsx" /* webpackChunkName: "component---src-pages-services-business-webrenewal-tsx" */),
  "component---src-pages-services-business-website-tsx": () => import("./../../../src/pages/services/business/website.tsx" /* webpackChunkName: "component---src-pages-services-business-website-tsx" */),
  "component---src-pages-services-ecommerce-analytics-tsx": () => import("./../../../src/pages/services/ecommerce/analytics.tsx" /* webpackChunkName: "component---src-pages-services-ecommerce-analytics-tsx" */),
  "component---src-pages-services-ecommerce-development-tsx": () => import("./../../../src/pages/services/ecommerce/development.tsx" /* webpackChunkName: "component---src-pages-services-ecommerce-development-tsx" */),
  "component---src-pages-services-ecommerce-ecsite-tsx": () => import("./../../../src/pages/services/ecommerce/ecsite.tsx" /* webpackChunkName: "component---src-pages-services-ecommerce-ecsite-tsx" */),
  "component---src-pages-services-ecommerce-internationalization-tsx": () => import("./../../../src/pages/services/ecommerce/internationalization.tsx" /* webpackChunkName: "component---src-pages-services-ecommerce-internationalization-tsx" */),
  "component---src-pages-services-ecommerce-shopify-tsx": () => import("./../../../src/pages/services/ecommerce/shopify.tsx" /* webpackChunkName: "component---src-pages-services-ecommerce-shopify-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-specified-commercial-transaction-law-tsx": () => import("./../../../src/pages/specifiedCommercialTransactionLaw.tsx" /* webpackChunkName: "component---src-pages-specified-commercial-transaction-law-tsx" */),
  "component---src-pages-term-of-use-chatbot-tsx": () => import("./../../../src/pages/term-of-use-chatbot.tsx" /* webpackChunkName: "component---src-pages-term-of-use-chatbot-tsx" */),
  "component---src-pages-web-optimization-tsx": () => import("./../../../src/pages/web-optimization.tsx" /* webpackChunkName: "component---src-pages-web-optimization-tsx" */),
  "component---src-pages-webiq-tsx": () => import("./../../../src/pages/webiq.tsx" /* webpackChunkName: "component---src-pages-webiq-tsx" */),
  "component---src-pages-website-tsx": () => import("./../../../src/pages/website.tsx" /* webpackChunkName: "component---src-pages-website-tsx" */)
}

